<template>
    <div class="page-apply-success">
        <div class="wrap">
            <van-checkbox :value="true" icon-size="1.02rem" checked-color="#F43011"></van-checkbox>
            <div class="inner">
                <div class="status">店铺申请提交成功</div>
                <div class="description">请耐心等待客服审核</div>
            </div>
            <div class="action-wrap">
                <van-button class="action" @click="confirm">
                    确定
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, Checkbox } from 'vant';
export default {
    name: 'PageApplySuccess',
    metaInfo: {
        title: '提交成功'
    },
    components: {
        [Checkbox.name]: Checkbox,
        [Button.name]: Button
    },
    methods: {
        confirm() {
            if (window.__wxjs_environment === 'miniprogram') {
                wx.miniProgram.switchTab({
                    url: '/pages/user/index'
                });
            } else {
                this.$router.go(-1);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.page-apply-success {
    width: 750px;
    margin: 0 auto;

    padding-bottom: 100px;
    .wrap {
        padding-top: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .status {
        font-size: 42px;
        font-weight: 500;
        line-height: 58px;
        color: #191919;
        margin-top: 40px;
        text-align: center;
    }
    .description {
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        color: #191919;
        margin-top: 12px;
        text-align: center;
    }
    .action-wrap {
        width: 100%;
        position: fixed;
        bottom: 158px;
        left: 0;
        text-align: center;
        & ::v-deep .action {
            width: 528px;
            height: 82px;
            background: #e13d1d;
            border-radius: 36px;
            font-size: 30px;
            line-height: 82px;
            color: #ffffff;
        }
        & ::v-deep .disabled {
            background-color: #e1861d;
        }
    }
}
</style>
